$ups-brown: rgba(51, 0, 0, 1);
$ups-brown-dark: rgba(89, 85, 85, 1);
$ups-brown-mid: #8c8585;
$ups-brown-lt: rgba(223, 220, 215, 0.7);
$ups-yellow: rgba(255, 209, 0, 1);
$ups-yellow-lt: rgba(255, 230, 115, 0.7);
$ups-plum: rgba(145, 0, 72, 1);
$ups-plum-lt: rgba(173, 64, 118, 1);
$ups-pink: rgba(194, 115, 154, 1);
$ups-sienna: rgba(198, 125, 48, 1);
$ups-teal: rgba(0, 133, 125, 1);
$ups-teal-lt: rgba(115, 188, 183, 1);
$ups-teal-mid: rgba(63, 163, 158, 1);
$ups-green: rgba(118, 136, 29, 1);
$ups-blue: rgba(66, 109, 169, 1);

$brand-footer: $ups-brown;

$sans: 'UPS Berlingske Sans', helvetica, arial, sans-serif;
$serif: 'UPS Berlingske Serif Tx', Georgia, Times, 'Times New Roman', serif;

.App {
  text-align: center;
  color: $ups-brown;
}

.MuiTableCell-root {
  padding: 10px 10px !important;
}

h5,
.h5 {
  font-weight: 600;
}
.App-logo {
  z-index: 2;
  transition: 0.3s;
  width: 35px;
  // height: 78px;
  float: left;
  margin: 1rem 0 0.4rem 0;
}

.App-header {
  background-color: rgba(119, 97, 87, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);

  // border-bottom: 5px solid $ups-yellow;
}

.App-footer {
  padding: 1rem 0;
  background-color: rgba(89, 85, 85, 0.8);
  color: white;
}

.App-link {
  color: $ups-sienna;
}

thead {
  font-size: 0.95rem;
  text-transform: uppercase;
  color: #333;
}

.profile {
  padding: 1rem;
}

@media (min-width: 768px) {
  .profile {
    padding: 2rem 1rem;
  }
}

.driver-avatar {
  max-width: 150px;
}

//bootstrap override
.navbar-light .navbar-nav .nav-link {
  color: white;
  font-weight: bold;
  font-family: Tahoma, helvetica, arial, sans-serif;
  padding: 0;
  margin: 1rem 1.5rem;
  border-bottom: 1px solid transparent;
  &:hover,
  &:active,
  &.active {
    color: white;
    text-decoration: underline;
  }
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.8);
  border-color: rgba(119, 97, 87, 1);
}
.card-title {
  border-bottom: 2px solid $ups-yellow;
  display: inline-block;
}

@media (min-width: 576px) {
  .App-logo {
    width: 67px;
    height: 78px;
  }
}
