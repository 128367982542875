@import '../fonts/fonts.css';

$sans: 'UPS Berlingske Sans', helvetica, arial, sans-serif;
$serif: 'UPS Berlingske Serif Tx', Georgia, Times, 'Times New Roman', serif;

body {
  margin: 0;
  font-family: $sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
