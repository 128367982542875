@font-face {
  font-family: 'UPS Berlingske Sans';
  src: url('UPSBerlingskeSans-Rg.eot');
  src: url('UPSBerlingskeSans-Rg.eot?#iefix') format('embedded-opentype'),
    url('UPSBerlingskeSans-Rg.woff2') format('woff2'),
    url('UPSBerlingskeSans-Rg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'UPS Berlingske Sans';
  src: url('UPSBerlingskeSans-Bd.eot');
  src: url('UPSBerlingskeSans-Bd.eot?#iefix') format('embedded-opentype'),
    url('UPSBerlingskeSans-Bd.woff2') format('woff2'),
    url('UPSBerlingskeSans-Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'UPS Berlingske Sans';
  src: url('UPSBerlingskeSans-XBd.eot');
  src: url('UPSBerlingskeSans-XBd.eot?#iefix') format('embedded-opentype'),
    url('UPSBerlingskeSans-XBd.woff2') format('woff2'),
    url('UPSBerlingskeSans-XBd.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}
