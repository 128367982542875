// Override default variables before the import
$body-bg: #fff;
$ups-brown: rgba(51, 0, 0, 1);
$ups-yellow: rgba(255, 209, 0, 1);
$theme-colors: (
  'primary': rgba(36, 36, 36, 0.9),
  'danger': #c91a13,
);

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
// @import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
// @import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import '~bootstrap/scss/utilities';
// @import "~bootstrap/scss/print";

.card {
  margin-bottom: 2rem;
}

.navbar {
  padding: 0 !important;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  font-weight: bold;
  font-family: Tahoma, helvetica, arial, sans-serif;
  padding: 0;
  margin: 1rem 1.5rem;
  border-bottom: 1px solid $ups-brown;
  &:hover,
  &:active,
  &.active {
    color: white;
    text-decoration: underline;
  }
}
.navbar-light .navbar-toggler {
  color: $ups-brown;
  background: rgba(255, 255, 255, 0.8);
  border-color: rgba(0, 0, 0, 0.1);
}
.card-title {
  border-bottom: 2px solid $ups-yellow;
  display: inline-block;
}

.breadcrumb {
  background-color: white;
  // border-radius: 0;
  font-size: 0.9rem;
  font-weight: 500;
  // padding: 0;
  a {
    text-decoration: underline;
  }
}
.breadcrumb-item.active {
  color: rgba(36, 36, 36, 0.9);
}

@include media-breakpoint-up(lg) {
  .navbar-light .navbar-nav .nav-link {
    &:hover,
    &:active,
    &.active {
      border-bottom: 1px solid rgba(223, 220, 215, 1);
      text-decoration: none !important;
    }
  }
}
